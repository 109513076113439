import React from 'react';
import { Route as RouteComponent } from 'react-router';
import Home from './pages/Home';
import Perfil from './pages/Perfil';
import Arracoamento from './pages/Producao/Arracoamento';
import Biometrias from './pages/Producao/Biometrias';
import Mortalidade from './pages/Producao/Mortalidade';
import Povoamento from './pages/Producao/Povoamento';
import HistoricoLote from './pages/Producao/Povoamento/Historico';
import Racao from './pages/Producao/Racao';
import Transferencia from './pages/Producao/Transferencia';
import Plantio from './pages/Producao/Plantio';
import Propriedade from './pages/Propriedade';
import Reservatorios from './pages/Propriedade/Reservatorios';
import Bancadas from './pages/Propriedade/Bancadas';
import HistoricoTanque from './pages/Propriedade/Historico';
import HistoricoBancada from './pages/Propriedade/Bancadas/Historico';
import Clientes from './pages/Vendas/Clientes';
import MinhasVendas from './pages/Vendas/MinhasVendas';
import Conta from './pages/Financeiro/Conta';
import Categoria from './pages/Financeiro/Categoria';
import Fornecedor from './pages/Financeiro/Fornecedor';
import Movimentacoes from './pages/Financeiro/Movimentacoes';
import PainelFinanceiro from './pages/Financeiro/Painel';

import Qualidade from './pages/Qualidade';
import RelatorioProducao from './pages/Relatorios/Producao';
import PainelProducao from './pages/Relatorios/Producao/VisaoGeral';
import DesempenhoLotes from './pages/Relatorios/Producao/DesempenhoLotes';
import DesempenhoTanques from './pages/Relatorios/Producao/DesempenhoTanques';
import ContaCorrente from './pages/Relatorios/Financeiro/ContaCorrente';
import FluxoDeCaixa from './pages/Relatorios/Financeiro/FluxoDeCaixa';
import ResumoVendas from './pages/Relatorios/Financeiro/ResumoVendas';
import ListagemVendas from './pages/Relatorios/Financeiro/ListagemDeVendas';

import ResumoLotes from './pages/Relatorios/Producao/ResumoLotes';
import ResumoTanques from './pages/Relatorios/Producao/ResumoTanques';
import EstoqueTanques from './pages/Relatorios/Producao/EstoqueTanques';
import Crescimento from './pages/Relatorios/Producao/Crescimento';
import ProgramasAlimentares from './pages/Producao/ProgramasAlimentares';
import TotalRacoes from './pages/Relatorios/Producao/TotalRacoes';
import ListagemMortalidade from './pages/Relatorios/Producao/ListagemMortalidade';

import CadastroArracoamentoPrograma from './pages/Producao/Arracoamento/CadastroArracoamentoPrograma';
import ImportacaoCSV from './pages/Producao/Arracoamento/ImportacaoCSV';
import CadastroV2ArracoamentoProgramaTanque from './pages/Producao/Arracoamento/CadastroV2ArracoamentoProgramaTanque';

import Insumos from './pages/Producao/Insumos';
import ComprasInsumos from './pages/Producao/Insumos/ComprasInsumos';
import AplicacaoInsumos from './pages/Producao/Insumos/AplicacaoInsumos';

import TanquesProgramaAlimentar from './pages/Relatorios/Producao/TanquesProgramaAlimentar';
import Rateio from './pages/Financeiro/Rateio';

import ResumoTotalRacoes from './pages/Relatorios/Producao/ResumoTotalRacoes';

import Temperatura from './pages/Qualidade/Temperatura';
import RacaoTanque from './pages/Relatorios/Producao/RacaoTanque';
import FormRelatorioFinanceiro from './pages/Financeiro/Painel/FormRelatorioFinanceiro';
import FormFluxoCaixa from './pages/Financeiro/Painel/FormFluxoCaixa';
import FormRelatorioRacoes from './pages/Financeiro/Painel/FormRelatorioRacoes';
import RelatorioCompraRacoes from './pages/Financeiro/Painel/relatorios/RelatorioCompraRacoes';
import ExtratoComprasClientes from './pages/Financeiro/Painel/relatorios/ExtratoComprasClientes';
import DepesaFornecedor from './pages/Financeiro/Painel/relatorios/DepesaFornecedor';
import Cheque from './pages/Financeiro/Cheque';
import PainelZootecnico from './pages/Relatorios/PainelZootecnico/PainelZootecnico';
import Estoque from './pages/Producao/Estoque';

import RelatorioCompraAnimais from './pages/Financeiro/Painel/relatorios/RelatorioCompraAnimais';
import RelatorioVendaProdutos from './pages/Financeiro/Painel/relatorios/RelatorioVendaProdutos';
import FormRelatoriosZootecnico from './pages/Relatorios/PainelZootecnico/FormRelatoriosZootecnico';
import HistoricoCa from './pages/Relatorios/PainelZootecnico/HistoricoCa';

import Usuarios from './pages/Usuarios';
import ArracoamentoPrevistoXRealizado from './pages/Relatorios/Producao/ArracoamentoPrevistoXRealizado';
import RelatorioAnalises from './pages/Qualidade/RelatorioAnalises';
import MinhaPropriedade from './pages/MinhaPropriedade';

export interface Route {
  name: string;
  component: React.FunctionComponent;
  path: string;
}

const routes = [
  {
    name: 'home',
    component: Home,
    path: '/home',
  },
  {
    name: 'perfil',
    component: Perfil,
    path: '/perfil',
  },
  {
    name: 'minhaPropriedade',
    component: MinhaPropriedade,
    path: '/minha-propriedade',
  },
  {
    name: 'propriedade',
    component: Propriedade,
    path: '/propriedade/tanques',
  },
  {
    name: 'historicoTanque',
    component: HistoricoTanque,
    path: '/propriedade/tanque/:id',
  },
  {
    name: 'reservatorios',
    component: Reservatorios,
    path: '/propriedade/reservatorios',
  },
  {
    name: 'bancadas',
    component: Bancadas,
    path: '/propriedade/bancadas',
  },
  {
    name: 'historicoBancada',
    component: HistoricoBancada,
    path: '/propriedade/bancada/:id',
  },
  {
    name: 'povoamento',
    component: Povoamento,
    path: '/producao/povoamento',
  },
  {
    name: 'historicoLote',
    component: HistoricoLote,
    path: '/producao/lote/:id',
  },
  {
    name: 'biometrias',
    component: Biometrias,
    path: '/producao/biometria',
  },
  {
    name: 'mortalidade',
    component: Mortalidade,
    path: '/producao/mortalidade',
  },
  {
    name: 'transferencia',
    component: Transferencia,
    path: '/producao/transferencia',
  },
  {
    name: 'racao',
    component: Racao,
    path: '/producao/racao',
  },
  {
    name: 'estoqueRacao',
    component: Estoque,
    path: '/producao/estoque',
  },
  {
    name: 'insumos',
    component: Insumos,
    path: '/producao/insumos',
  },
  {
    name: 'comprasInsumos',
    component: ComprasInsumos,
    path: '/producao/insumos/compras',
  },
  {
    name: 'aplicacaoInsumos',
    component: AplicacaoInsumos,
    path: '/producao/insumos/aplicacao',
  },
  {
    name: 'arracoamento',
    component: Arracoamento,
    path: '/producao/arracoamento',
  },
  {
    name: 'novo-arracoamento-programa',
    component: CadastroArracoamentoPrograma,
    path: '/producao/novo-arracoamento-programa',
  },
  {
    name: 'novo-arracoamento-programa-por-tanque',
    component: CadastroV2ArracoamentoProgramaTanque,
    path: '/producao/v2-novo-arracoamento-programa-por-tanque',
  },
  {
    name: 'importacao-csv',
    component: ImportacaoCSV,
    path: '/producao/importacao-csv',
  },
  {
    name: 'programas-alimentares',
    component: ProgramasAlimentares,
    path: '/producao/programas-alimentares',
  },
  {
    name: 'plantio',
    component: Plantio,
    path: '/producao/plantio',
  },
  {
    name: 'clientes',
    component: Clientes,
    path: '/vendas/clientes',
  },
  {
    name: 'vendas',
    component: MinhasVendas,
    path: '/vendas',
  },
  {
    name: 'qualidade',
    component: Qualidade,
    path: '/qualidade-da-agua',
  },
  {
    name: 'temperatura',
    component: Temperatura,
    path: '/temperatura',
  },
  {
    name: 'Relatório de Análises',
    component: RelatorioAnalises,
    path: '/qualidade-da-agua/relatorio-analises',
  },
  {
    name: 'contas',
    component: Conta,
    path: '/financeiro/contas',
  },
  {
    name: 'categorias',
    component: Categoria,
    path: '/financeiro/categorias',
  },
  {
    name: 'fornecedores',
    component: Fornecedor,
    path: '/financeiro/fornecedores',
  },
  {
    name: 'Rateio de Custo Fixo',
    component: Rateio,
    path: '/financeiro/rateio',
  },
  {
    name: 'Painel Financeiro',
    component: PainelFinanceiro,
    path: '/financeiro/painel/dashboard',
  },
  {
    name: 'Relatório Financeiro',
    component: FormRelatorioFinanceiro,
    path: '/financeiro/painel/relatorio-financeiro',
  },
  {
    name: 'Fluxo de Caixa',
    component: FormFluxoCaixa,
    path: '/financeiro/painel/fluxo-caixa',
  },
  {
    name: 'Relatório de Compras e Vendas',
    component: FormRelatorioRacoes,
    path: '/financeiro/painel/relatorio-compra-venda',
  },
  {
    name: 'Relatório de Compras de Ração',
    component: RelatorioCompraRacoes,
    path: '/financeiro/painel/relatorio-financeiro/compra-racao',
  },
  {
    name: 'Relatório de Compras de Animais',
    component: RelatorioCompraAnimais,
    path: '/financeiro/painel/relatorio-financeiro/compra-animais',
  },
  {
    name: 'Extrato de Vendas por Clientes',
    component: RelatorioVendaProdutos,
    path: '/financeiro/painel/relatorio-financeiro/venda-produtos',
  },
  {
    name: 'Extrato de Vendas por Clientes',
    component: ExtratoComprasClientes,
    path: '/financeiro/painel/relatorio-financeiro/compra-clientes',
  },
  {
    name: 'Resumo de Despesas por Fornecedor',
    component: DepesaFornecedor,
    path: '/financeiro/painel/relatorio-financeiro/despesa-fornecedor',
  },
  {
    name: 'movimentacoes',
    component: Movimentacoes,
    path: '/financeiro/movimentacao/:boolean?',
  },
  {
    name: 'cheque',
    component: Cheque,
    path: '/financeiro/controle-cheque',
  },
  {
    name: 'painelProducao',
    component: RelatorioProducao,
    path: '/relatorios/producao',
  },
  {
    name: 'painelProducao',
    component: PainelProducao,
    path: '/relatorios/visao-geral',
  },
  {
    name: 'desempenhoLotes',
    component: DesempenhoLotes,
    path: '/relatorios/desempenho-lotes',
  },
  {
    name: 'desempenhoTanques',
    component: DesempenhoTanques,
    path: '/relatorios/desempenho-tanques',
  },
  {
    name: 'resumoLotes',
    component: ResumoLotes,
    path: '/relatorios/resumo-lotes',
  },
  {
    name: 'resumoTanques',
    component: ResumoTanques,
    path: '/relatorios/resumo-tanques',
  },
  {
    name: 'estoqueTanques',
    component: EstoqueTanques,
    path: '/relatorios/estoque-tanques',
  },
  {
    name: 'tanquesProgramaAlimentar',
    component: TanquesProgramaAlimentar,
    path: '/relatorios/tanques-programa-alimentar',
  },
  {
    name: 'contaCorrente',
    component: ContaCorrente,
    path: '/relatorios/conta-corrente',
  },
  {
    name: 'fluxoDeCaixa',
    component: FluxoDeCaixa,
    path: '/relatorios/fluxo-de-caixa',
  },
  {
    name: 'resumoVendas',
    component: ResumoVendas,
    path: '/relatorios/resumo-vendas',
  },
  {
    name: 'resumoVendas',
    component: PainelZootecnico,
    path: '/relatorios/painel-zootecnico',
  },
  {
    name: 'historicoCA',
    component: HistoricoCa,
    path: '/relatorios/historico-ca',
  },
  {
    name: 'relatoriosZootecnico',
    component: FormRelatoriosZootecnico,
    path: '/relatorios/relatorios-zootecnicos',
  },
  {
    name: 'listagemVendas',
    component: ListagemVendas,
    path: '/relatorios/listagem-vendas',
  },
  {
    name: 'totalRacoes',
    component: TotalRacoes,
    path: '/relatorios/total-racoes',
  },
  {
    name: 'totalRacoes',
    component: ResumoTotalRacoes,
    path: '/relatorios/resumo-total-racoes',
  },
  {
    name: 'listagemMortalidade',
    component: ListagemMortalidade,
    path: '/relatorios/listagem-mortalidade',
  },
  {
    name: 'racaoTanque',
    component: RacaoTanque,
    path: '/relatorios/racao-tanque',
  },
  {
    name: 'arracoamentoPrevistoXRealziado',
    component: ArracoamentoPrevistoXRealizado,
    path: '/relatorios/arracoamento-previsto-x-realizado',
  },
  {
    name: 'Crescimento',
    component: Crescimento,
    path: '/relatorios/crescimento',
  },
  {
    name: 'Usuarios',
    component: Usuarios,
    path: '/usuarios',
  },
];

const Routes: React.FC = () => {
  return (
    <>
      {routes.map((route) => (
        <RouteComponent key={route.name} exact path={route.path} component={route.component} />
      ))}
    </>
  );
};

export default Routes;
