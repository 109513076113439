import React from 'react';
import { Box } from '@material-ui/core';

import Botao from '../../components/Botoes/Botao';
import { ModalBody, ModalHeader, Modal } from '../../components/Modais/styles';
import { observer, useLocalStore } from 'mobx-react-lite';
import Select from '../../components/Select';
import useStore from '../../services/hooks/useStore';
import InputNumero from '../../components/InputNumero';

type Props = ModalProps & {
  onConfirm(data: { especie: SelectRow | null; peso_medio_despesca: number } | null): void;
  pesoMedioList: { especie: SelectRow | null; peso_medio_despesca: number; tempo_pm_lote: number }[];
  especieID: number;
};

const AdicionarPesoMedio: React.FC<Props> = ({ open, handleClose, onConfirm, pesoMedioList, especieID }) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    especie: especieID
      ? {
          value: pesoMedioList.find((item) => item.especie?.value === especieID)?.especie?.value || null,
          label: pesoMedioList.find((item) => item.especie?.value === especieID)?.especie?.label || '',
        }
      : null,
    peso_medio_despesca: pesoMedioList.find((item) => item.especie?.value === especieID)?.peso_medio_despesca || 0,
    tempo_pm_lote: pesoMedioList.find((item) => item.especie?.value === especieID)?.tempo_pm_lote || 0,
  }));

  const handleSave = () => {
    if (!state.especie || state.peso_medio_despesca <= 0) {
      store.notificar('Por favor, selecione uma espécie e informe um peso médio válido.');
      return;
    }
    const especieExistente = pesoMedioList.find((item) => item.especie?.value === state.especie?.value);
    if (especieExistente && especieID === 0) {
      store.notificar('Essa espécie já foi adicionada à lista.');
      return;
    }

    const pesoMedioData = {
      especie: state.especie,
      peso_medio_despesca: state.peso_medio_despesca,
      tempo_pm_lote: state.tempo_pm_lote,
    };
    onConfirm(pesoMedioData);
    handleClose();
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>
        <Box display="flex" justifyContent="space-between" width="100%">
          <span>Peso Médio por Espécie</span>
        </Box>
      </ModalHeader>
      <ModalBody>
        <Select
          placeholder="Espécie"
          onChange={(e) => (state.especie = e as SelectRow)}
          options={store.especies.getSelectRows()}
          value={state.especie}
        />
        <br />
        <InputNumero
          precision="0"
          value={state.tempo_pm_lote}
          onChange={(e) => (state.tempo_pm_lote = e)}
          label="Tempo Peso Médio do Lote (dias)"
        />
        <br />
        <InputNumero
          value={state.peso_medio_despesca}
          onChange={(value) => (state.peso_medio_despesca = value)}
          precision="3"
          label="Peso Médio Despesca (g)"
        />
      </ModalBody>

      <div style={{ width: 300, display: 'flex', padding: '20px 0' }}>
        <Botao onClick={handleClose} cor="gray">
          Voltar
        </Botao>
        <Botao onClick={handleSave} cor="#00C853">
          Salvar
        </Botao>
      </div>
    </Modal>
  );
};

export default observer(AdicionarPesoMedio);
