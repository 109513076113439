import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import InputNumero from '../../../components/InputNumero';
import useStore from '../../../services/hooks/useStore';
import Select from '../../../components/Select';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Formatter from '../../../services/Formatter';

type Props = ModalProps & {
  categoria: string;
  loteTanque: LoteTanque | null;
  onConfirm(
    tanqueID: number,
    quantidade: number,
    biomassa: number,
    pesoMedio: number,
    saldoTanque: boolean,
    bonificacao: boolean,
    bonificacaoPorc: number,
    bonificacaoQtde: number,
    valorUnitario: number,
    valorTotal: number,
    finalizarTanque: boolean,
  ): void;
};
const QuantidadeModalAlevino: React.FC<Props> = ({ open, handleClose, loteTanque, onConfirm, categoria }) => {
  const [quantidade, setQuantidade] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);
  const [valorUnitario, setValorUnitario] = useState(0);
  const [biomassa, setBiomassa] = useState(0);
  const [pesoMedio, setPesomedio] = useState(0);
  const [porcentagem, setPorcentagem] = useState(0);
  const [quantidadeVendida, setQuantidadeVendida] = useState(0);
  const [saldoTanque, setSaldoTanque] = useState(false);
  const [bonificacao, setBonificacao] = useState(false);
  const [status, setStatus] = useState({
    label: 'Biomassa',
    value: 2,
  });
  const [finalizarTanque, setfinalizarTanque] = useState(false);
  const store = useStore();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function changeSaldoTanque(saldoTanqueProps: boolean) {
    saldoTanqueProps ? setBiomassa(0) : setBiomassa((loteTanque!.peso_medio * loteTanque!.estoque) / 1000);
    saldoTanqueProps ? setPesomedio(0) : setPesomedio(loteTanque!.peso_medio);
    saldoTanqueProps
      ? setQuantidade(0)
      : setQuantidade(status.value === 2 ? loteTanque!.estoque : loteTanque!.estoque / 1000);
    saldoTanqueProps && setQuantidade(0);
    saldoTanqueProps && setValorTotal(0);
    saldoTanqueProps && setValorUnitario(0);
    saldoTanqueProps && setPorcentagem(0);
    saldoTanqueProps && setQuantidadeVendida(0);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function changeBiomassaPesoMedio() {
    if (biomassa && pesoMedio) {
      setQuantidade(Math.round((biomassa * 100) / pesoMedio));
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function changePorcentagem(e: number) {
    const res = status.value === 2 ? (quantidade * e) / 100 : (quantidade * 1000 * e) / 100;
    setQuantidadeVendida(res);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function changeQuantidadeVendida(e: number) {
    const res = status.value === 2 ? (e * 100) / quantidade : (e * 100) / quantidade / 1000;
    setPorcentagem(res);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function changeValorUnitario(e: number) {
    const res = e / (quantidade * 1000);
    setValorUnitario(res);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function changeValorTotal(e: number) {
    const res = e * quantidade;
    setValorTotal(res);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function changeValorTotalCamarão(e: number) {
    const res = e * biomassa;
    setValorTotal(res);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function changeBiomassa(e: number) {
    const res = e * quantidade;
    setBiomassa(res);
  }

  function addTanque(): void {
    if (biomassa === 0 || isNaN(biomassa)) store.notificar('Informe a biomassa');
    else if (quantidade === 0 || isNaN(quantidade)) store.notificar('Informe a quantidade');
    else if (quantidade > loteTanque!.estoque) store.notificar('A quantidade excede o total de animais do tanque');
    else {
      loteTanque!.tanque &&
        onConfirm(
          loteTanque!.tanque.id,
          status.value === 2 ? quantidade : quantidade * 1000,
          biomassa,
          pesoMedio / 1000,
          saldoTanque,
          bonificacao,
          porcentagem,
          quantidadeVendida,
          valorUnitario,
          valorTotal,
          finalizarTanque,
        );
      handleClose();
    }
  }

  return (
    <Modal open={open} onClose={handleClose} style={{ overflow: 'hidden' }}>
      <ModalHeader>Informações do tanque</ModalHeader>
      <ModalBody>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            margin: '0 auto',
            boxShadow: '0px 5px 5px #ccc',
            padding: '.5rem',
            marginBottom: '10px',
            overflow: 'hidden',
          }}
        >
          <p>{(loteTanque!.tanque && loteTanque!.tanque.nome) || (loteTanque!.tanque && loteTanque!.tanque.codigo)}</p>
          <p>{Formatter.formatNumber(0, loteTanque!.estoque)}un</p>
          <p>{Formatter.formatNumber(2, loteTanque!.biomassa / 1000)} Kg</p>
        </div>
        {categoria === 'Alevinos' && (
          <Select
            defaultValue={status}
            options={[
              { label: 'Biomassa', value: 2 },
              { label: 'Milheiro', value: 1 },
            ]}
            onChange={(e: any) => {
              setStatus(e);
              setSaldoTanque(false);
              changeSaldoTanque(true);
            }}
            placeholder="Milheiro ou Biomassa"
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={saldoTanque}
              color="default"
              onChange={async () => {
                setSaldoTanque(!saldoTanque);
                changeSaldoTanque(saldoTanque);
              }}
            />
          }
          style={{ width: '100%', display: 'flex', justifyContent: 'start' }}
          label="Saldo do tanque"
        />

        {status.value === 2 ? (
          <>
            <InputNumero
              disabled={saldoTanque}
              value={biomassa}
              onChange={(value) => {
                setBiomassa(value);
                changeBiomassaPesoMedio();
              }}
              precision="3"
              label="Biomassa para venda (kg)"
            />

            <InputNumero
              value={status.value === 2 ? pesoMedio : pesoMedio / 1000}
              onChange={(value) => {
                setPesomedio(value);
                changeBiomassaPesoMedio();
              }}
              precision="3"
              label={categoria === 'Camarão' ? 'Peso Médio (g)' : 'Peso Médio (g)'}
            />
            {categoria === 'Alevinos' ? (
              <InputNumero
                disabled={!pesoMedio}
                value={valorUnitario}
                onChange={(value) => {
                  setValorUnitario(value);
                  changeValorTotal(value);
                }}
                precision="2"
                label="Valor Unitário (R$/Un)"
              />
            ) : (
              <InputNumero
                disabled={!pesoMedio}
                value={valorUnitario}
                onChange={(value) => {
                  setValorUnitario(value);
                  changeValorTotalCamarão(value);
                }}
                precision="2"
                label="Valor Unitário (R$/Kg)"
              />
            )}
          </>
        ) : (
          <>
            <InputNumero
              disabled={saldoTanque}
              value={quantidade}
              onChange={(value) => setQuantidade(value)}
              precision="3"
              label="Milheiro para venda"
            />

            <InputNumero
              value={pesoMedio}
              disabled={!quantidade}
              onChange={(value) => {
                setPesomedio(value);
                changeBiomassa(value);
              }}
              precision="3"
              label={'Peso Médio (g)'}
            />
            <InputNumero
              disabled={!pesoMedio}
              value={valorTotal}
              onChange={(value) => {
                setValorTotal(value);
                changeValorUnitario(value);
              }}
              precision="2"
              label="Valor Total (R$)"
            />
          </>
        )}
        {categoria === 'Alevinos' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={bonificacao}
                color="default"
                onChange={() => {
                  setBonificacao(bonificacao ? false : true);
                }}
              />
            }
            style={{ width: '100%', display: 'flex', justifyContent: 'start' }}
            label="Possui Bonificação?"
          />
        )}
        {bonificacao && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: '360px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '48%',
              }}
            >
              <InputNumero
                value={porcentagem}
                onChange={(value) => {
                  setPorcentagem(value);
                  changePorcentagem(value);
                }}
                precision="2"
                label="Informe o %"
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '48%',
                overflow: 'hidden',
              }}
            >
              <InputNumero
                value={quantidadeVendida}
                onChange={(value) => {
                  setQuantidadeVendida(value);
                  changeQuantidadeVendida(value);
                }}
                precision="0"
                label="Informe a quantidade (un)"
              />
            </div>
          </div>
        )}
        <div style={{ marginBottom: '10px', width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={finalizarTanque}
                color="default"
                onChange={() => {
                  setfinalizarTanque(!finalizarTanque);
                }}
              />
            }
            style={{ width: '100%', display: 'flex', justifyContent: 'start' }}
            label="Finalizar Tanque?"
          />
          {finalizarTanque && (
            <p color="error" style={{ width: '100%', display: 'flex', justifyContent: 'start', color: '#C80000' }}>
              O saldo dos animais serão lançados como mortalidades!
            </p>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {status.value === 2 ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>Valor Total</span>
                <p style={{ color: '#059DE7', fontSize: '30px' }}>
                  {valorTotal !== null && valorTotal !== undefined
                    ? `R$ ${Formatter.formatNumber(2, valorTotal)}`
                    : 'N/A'}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>Quantidade (un)</span>
                <p style={{ color: '#059DE7', fontSize: '30px' }}>{Formatter.formatNumber(0, quantidade)}</p>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>Valor Unitário</span>
                <p style={{ color: '#059DE7', fontSize: '30px' }}>R${Formatter.formatNumber(2, valorUnitario)}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>Biomassa Total (Kg)</span>
                <p style={{ color: '#059DE7', fontSize: '30px' }}>{Formatter.formatNumber(3, biomassa)}</p>
              </div>
            </>
          )}
        </div>

        {loteTanque && (
          <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
            <Botao cor="gray" onClick={handleClose}>
              Voltar
            </Botao>
            <Botao cor="#00C853" onClick={addTanque}>
              Confirmar
            </Botao>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default QuantidadeModalAlevino;
